* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
  }
  
  body {
    background-color: #f5f5f5;
  }
  
  .search-header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 1rem;
    background: #ffd41c;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  
  .main-content {
    margin-top: 70px;
    margin-bottom: 70px;
    padding: 1rem;
  }
  
  .profile-section {
    background: white;
    border-radius: 15px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .profile-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .profile-pic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #ffd41c;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 2rem;
  }
  
  .profile-info h2 {
    text-align: left;
    color: #333;
    margin-bottom: 0.5rem;
  }
    
  .profile-info p {
    text-align: left;
  }
  
  .profile-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .stat-box {
    background: #f8f8f8;
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
  }
  
  .stat-number {
    font-size: 1.5rem;
    color: #ffd41c;
    font-weight: bold;
  }
  
  .profile-actions {
    display: grid;
    gap: 1rem;
  }
  
  .action-button {
    padding: 1rem;
    border: none;
    border-radius: 10px;
    background: #ffd41c;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .action-button:hover {
    background: #e0bb0a;
  }
  
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    padding: 0.8rem;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .nav-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .nav-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #666;
    text-decoration: none;
    font-size: 0.8rem;
  }
  
  .nav-button.active {
    color: #ffd41c;
  }
  
  .nav-button i {
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
  }
  
  .profile-logout {
    margin-top: 1rem;
    text-align: center;
  }
  
  .logout-button {
    padding: 1rem;
    width: 100%;
    background-color: black;
    border: none;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .logout-button:hover {
    background-color: grey;
  }  
  
  @media (max-width: 768px) {
    .profile-info h2 {
      text-align: center;
    }

    .profile-info p {
      text-align: center;
    }

    .profile-header {
      flex-direction: column;
      align-items: center;
    }
  
    .profile-pic {
      width: 60px;
      height: 60px;
      font-size: 1.5rem;
    }
  
    .profile-stats {
      grid-template-columns: 1fr; 
    }
  
    .action-button, .logout-button {
      padding: 0.8rem;
      font-size: 1rem;
      width: 100%;
    }
  
    .profile-section {
      padding: 1rem;
    }
  }