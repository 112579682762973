.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #666;
  transition: color 0.3s ease;
}

.footer-item:hover,
.footer-item.active {
  color: #ffd41c;
}

.footer-item i {
  font-size: 20px;
  margin-bottom: 5px;
}

.footer-item span {
  font-size: 12px;
}

.home-container {
  padding-bottom: 80px; 
}
