* {
    box-sizing: border-box;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body {
    background-color: #f0f2f5;
    margin: 0;
    padding: 20px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.home-container {
    padding-top: 90px; 
}

.slick-next:before,
.slick-prev:before {
    color: #000 !important;

}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.item {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background-color 0.3s;
    cursor: pointer;
}

.item img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
}

.form-group {
    margin-bottom: 15px;
}

input, textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  textarea {
    resize: vertical;
    min-height: 60px;
  }

  input, select {
      width: 100%;
      padding: 8px;
      margin-top: 5px;
      border: 1px solid #ddd;
      border-radius: 4px;
  }

  button {
      background-color: #ffd41c;
      color: black;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  }

  button:hover {
      background-color: #e0bb0a;
  }


  #resultado {
      margin-top: 20px;
      padding: 20px;
      background-color: #e8f5e9;
      border-radius: 10px;
  }

  .carousel-item {
      padding: 10px;
    }
    
  .item {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, background-color 0.3s;
    cursor: pointer;
  }
  
  .item.selected {
    background-color: #ffd41c;
    color: #000;
  }
  
  .item h3 {
    margin-top: 10px;
    font-size: 16px;
  }
  
  .item p {
    margin-top: 5px;
    font-size: 14px;
  }
  
  .custom-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 34px;
  }

  .pedido-card {
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .pedido-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  .status-badge {
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.9em;
    font-weight: 500;
    background-color: #fff3cd;
    color: #856404;
    border: 1px solid #ffeeba;
  }
  
  .pedido-details {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  
  .pedido-actions {
    display: flex;
    margin-top: 15px;
  }
  
  .btn {
    border-radius: 8px;
    padding: 8px 12px;
    transition: background-color 0.3s, color 0.3s;
    width: 140px;
  }
  
  .action-buttons{
    display: flex;
    justify-content: space-between;
  }

  .btn-outline-danger {
    border-color: #dc3545;
  }
  
  .btn-outline-danger:hover {
    color: #fff;
  }

  .btn-adicionar {
    background-color: #ffd41c;
    padding: 8px 12px;
  }

  .btn-adicionar:hover {
    background-color: #f7e286;
  }

  .work-requests {
    background-color: #ffd41c;
    margin-top: 50px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .work-requests-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
  
  .arrow-circle {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f7e286;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .arrow-circle span {
    color: #000;
    font-size: 20px; 
    line-height: 1;
    display: flex; 
    align-items: center;
    justify-content: center;
  }

  .item-box {
    margin-top: 36px;
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
}

.item-box-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  margin-top: 10px;
}

.item-box-title span {
  margin-top: 10px;
  text-align: left;
}

.desentulha-bold {
  font-weight: bold;
}

.request-card {
  background: white;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.map-container {
  height: 200px;
  margin: 1rem 0;
  border-radius: 10px;
  overflow: hidden;
}

.text-information-job{
  text-align: left;
}
