.button-color {
    background-color: #ffd41c;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button-color:hover {
    background-color: #e0bb0a;
}

.link {
    color: #ffd41c;
    text-decoration: none;
}