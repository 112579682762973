.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffd41c;
  color: white;
  padding: 20px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.back-arrow {
  cursor: pointer;
  color: black;
  font-size: 24px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-arrow:hover {
  opacity: 0.8; /* Efeito de hover */
}

.search-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  position: relative;
}

.search-input {
  flex: 1;
  padding: 12px 45px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #666;
}
