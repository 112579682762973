.pedido-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    margin-top: 10px;
  }
  
  .add-items{
    margin-top: 36px;
    margin-bottom: 36px;
  }
  .item-adicionado{
    padding-bottom: 20px;
}
  .pedido-info p {
    margin: 5px 0;
    text-align: left;
  }

  .pedido-info h2 {
    margin: 5px 0;
    text-align: left;
  }
  
  .item-image {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .add-items-header {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pedido-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bg-button-success {
    background-color: #ffd41c;
    color: black;
  }
  .bg-button-success:hover {
    background-color: #ffd41c;
    cursor: default;
  }